































import { Component, Inject, Vue } from 'vue-property-decorator';
import PageHeader1 from '@/components/PageHeader1.vue';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';
import { ApiFacade } from '@/services/ApiFacade';
import { TrainingMaterialDto } from '@/models/dto/TrainingMaterialsDto';

@Component({
  components: { PageHeader1 },
})
export default class VpnAccessView extends Vue {
  @Inject() appStore!: Context<AppModule>;
  @Inject() apiFacade!: ApiFacade;

  private isLoading = false;
  private docs: TrainingMaterialDto[] = [];

  async mounted() {
    try {
      this.isLoading = true;
      this.docs = await this.apiFacade.fetchTrainingMaterials();
    } catch (e) {
      console.error('Fetch docs error', e);
    } finally {
      this.isLoading = false;
    }
  }
}
